
import { IonPage, IonContent } from '@ionic/vue';
import { close } from 'ionicons/icons'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'RecoverPassword',
  components: { 
    IonContent, 
    IonPage 
  },
  data () {
    return {
      email: '',
      password: '',
      sent: false,
      close
    }
  },
  computed: {
    submitted (): boolean {
      return (this as any).sent;
    }
  },
  methods: {
    goHome () {
      (this as any).$router.push('/');
    },
    async submit () {
      const vm = (this as any);
      if (vm.email.length) {
        api.recoverPassword(vm.email)
          .then((result: any) => {
            if (result.status === 200) {
              vm.sent = true;
            } 
          })
          .catch((error: any) => {
            console.log(error);
            vm.sent = true;
          });
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave login');
    (this as any).sent = false;
  },
  ionViewDidEnter () {
    console.log('[ionView] entered login');
  }
}
